import {createContext, useContext, useEffect, useState} from "react";

export const ThemeContext = createContext({});

export const useTheme = () => {
    return useContext(ThemeContext)
}

export const ThemeContextProvider = ({children}) => {

    const [theme, setTheme] = useState('light')

    useEffect(() => {
        let theme = localStorage.getItem('theme')
        if (theme) {
            theme = JSON.parse(theme)?.theme
            setTheme(theme)
        }
    }, [])

    const changeTheme = (new_theme) => {
        localStorage.setItem('theme', JSON.stringify({
            theme: new_theme
        }))
        setTheme(new_theme)
    }

    return (
        <ThemeContext.Provider value={{
            theme,
            changeTheme
        }}>
            {children}
        </ThemeContext.Provider>
    )
}