import React, {useEffect, useRef, useState} from 'react'
import Link from "next/link";
import {ImageWrapper} from "../ImageWrapper/ImageWrapper";
import zeniq_long from "../../public/assets/nomo-long.jpg";
import zeniq from "../../public/assets/nomo.jpg";
import {useRouter} from 'next/router'
import appstore from "../../public/assets/appstore.png";
import playstore from "../../public/assets/googleplay.png";
import { iOSDownloadURL, androidDownloadURL} from "../ImageWrapper/ImageWrapper";

function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

const Header = () => {

    const router = useRouter()
    const active = router.pathname
    const header = useRef()
    const [mobileOS, setMobileOS] = useState('')


    useEffect(() => {
        setMobileOS(getMobileOperatingSystem())
        console.log(mobileOS)
    }, [])


    let header_theme;
    let button = true;
    switch (active) {
        case "/downloads":
            // header_theme = 'black-header'
            button = false
            break
        default:
            header_theme = 'white-header'
    }

    return(
        <>
            <div id='header' className={"header-container " + header_theme}>
                {mobileOS &&
                    <div className="container-top">
                        <div>
                            <div className="left">
                                <Link href={"/"}>
                                    <div className="ZENIQ-logo">
                                        <ImageWrapper image={zeniq_long} width={739/3.3} height={166/3.3} layout={'fixed'} priority={true}/>
                                    </div>
                                </Link>
                                <Link href={"/"}>
                                    <div className="ZENIQ-logo-short">
                                        <ImageWrapper image={zeniq} width={50} height={50} layout={'fixed'} priority={true}/>
                                    </div>
                                </Link>
                            </div>
                            <div className="right">
                                {
                                    mobileOS === 'unknown' && button &&
                                    <>
                                        <Link href={"/install"}>
                                            <div className={(active === '/nomo' ? 'active' : '') + " button-outline"}>Get
                                                started with Nomo
                                            </div>
                                        </Link>
                                    </>
                                }
                                {

                                    mobileOS === 'iOS' &&
                                    <a href={iOSDownloadURL}
                                       target={'_blank'} rel={'noreferrer'}>
                                        <ImageWrapper image={appstore} width={160} height={50} priority={true}/>
                                    </a>
                                }
                                {
                                    mobileOS === 'Android' &&
                                    <a href={androidDownloadURL} target={'_blank'} rel={'noreferrer'}>
                                        <div className="button">
                                            <ImageWrapper image={playstore} width={170} height={50} priority={true}/></div>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="separator"/>
            </div>
            <div ref={header} className="header-mobile-container">

                <div className="separator"/>
            </div>
        </>
    )
}

export default Header