import React from 'react'
import Image from 'next/image'

export const playstoreDownloadURL =
 "https://play.google.com/store/apps/details?id=app.nomo";
export const androidDownloadURL = "https://nomo.app/downloads/app-release.apk";
export const iOSDownloadURL =
  "https://apps.apple.com/app/nomo-powered-by-zeniq/id1660767200";


export const ImageWrapper = ({image, height, width, layout, priority}) => {
    return(
        <div className="image-wrapper" style={{
            height: height ? height : '100%',
            width: width ? width : '100%',

        }}>
            <Image src={image} alt='' width={width} height={height} layout={layout || "fixed"} priority={priority}/>
        </div>
    )
}