import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <p>© 2024 Nomo. All rights reserved.</p>
                <p>Contact us at <a href="mailto:support@nomo.app">support@nomo.app</a></p>
            </div>
        </footer>
    );
};

export default Footer;